import { Get, Post } from "../../apiServices/apiHandler.service";
import { createAsyncAction } from "redux-promise-middleware-actions";
import { servicemanagerBaseUrl } from "apiServices/config.service";

export const walletActionTypes = {
  FETCH_TRANSACTIONS_BY_ACCOUNT: "FETCH_TRANSACTIONS_BY_ACCOUNT",
  UPDATE_TRANSACTION: "UPDATE_TRANSACTION",
  DELETE_TRANSACTION: "DELETE_TRANSACTION",

  TOP_UP_WALLET: "TOP_UP_WALLET",
  WITHDRAW: "WITHDRAW",
  FETCH_WALLET: "FETCH_WALLET",
  CREATE_WALLET: "CREATE_WALLET",
  GET_WALLET: "GET_WALLET",
  GENERATE_BANK_ACCOUNT: "GENERATE_BANK_ACCOUNT",
  CONVERTWALLETVALUE: "CONVERTWALLETVALUE",
  CONFIRMCONVERSION: "CONFIRMCONVERSION",
  GET_CURRENCY: "GET_CURRENCY",
};

export const fetchWallet = createAsyncAction(
  walletActionTypes.GET_WALLET,
  async (payload) => {
    return await Get(`corporateWallet/wallets/client`, payload);
  }
);
export const topUpWallet = createAsyncAction(
  walletActionTypes.TOP_UP_WALLET,
  async (payload) => {
    return await Post(`TopupWallet`, payload);
  }
);

export const withdrawFromWallet = createAsyncAction(
  walletActionTypes.WITHDRAW,
  async (payload) => {
    return await Post(`WithdrawFromWallet`, payload);
  }
);

export const fetchWalletByAccount = createAsyncAction(
  walletActionTypes.FETCH_WALLET,
  async (account) => {
    return await Get(`corporateWallet/${account}`);
  }
);

export const fetchTransactionsByAccount = createAsyncAction(
  walletActionTypes.FETCH_TRANSACTIONS_BY_ACCOUNT,
  async (account) => {
    return await Get(`corporateWallet/history?accountNumber=${account}`);
  }
);

export const createWalletByCurrency = createAsyncAction(
  walletActionTypes.CREATE_WALLET,
  async (payload) => {
    return await Post("corporateWallet/openaccount", payload);
  }
);
export const generateBankAccount = createAsyncAction(
  walletActionTypes.GENERATE_BANK_ACCOUNT,
  async (payload) => {
    return await Post(
      "v1/Collection/createvirtualaccount",
      payload,
      servicemanagerBaseUrl
    );
  }
);

export const convertWalletvalue = createAsyncAction(
  walletActionTypes.CONVERTWALLETVALUE,
  async (payload) => {
    return await Post("corporateWallet/ConvertWalletValue", payload);
  }
);
export const confirmConversion = createAsyncAction(
  walletActionTypes.CONFIRMCONVERSION,
  async (payload) => {
    return await Post("corporateWallet/ConfirmConversion", payload);
  }
);
export const getCurrency = createAsyncAction(
  walletActionTypes.GET_CURRENCY,
  async () => {
    return await Get(
      "v1/Currency/GetAll?pageSize=1000&pageNumber=1",
      servicemanagerBaseUrl
    );
  }
);
