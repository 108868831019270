import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "vgg-ui-react/dist/components/Core";
import OtpForm from "components/otpForm/OtpForm";
import { useYupValidationResolver } from "helpers/yupValidationResolver";

import "./withdrawalForm.scss";
import Stepper from "components/stepper/Stepper";
import AccountInfo from "./AccountInfo";
import AmountInfo from "./AmountInfo";
import OtpInfo from "./AmountInfo";

const stepsArray = ["Account Info", "Amount", "OTP Confirm"];

const bankOptions = [
  { label: "Access Bank", value: "access" },
  { label: "Guaranteed Trust Bank", value: "gtb" },
  { label: "Keystone Bank", value: "keystone" },
  { label: "United Bank for Africa", value: "uba" },
  { label: "Zenith Bank", value: "zenith-bank" },
];

const WithdrawalForm = ({ open, setOpen, title, submitForm }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [otpVerified] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!open) {
      accountInfoForm.reset();
      amountInfoForm.reset();
      otpInfoForm.reset();
      setCurrentStep(1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const acctInfoSchema = Yup.object({
    accountNumber: Yup.string().required("Please enter an account number"),
    bank: Yup.string().required("Please select an bank"),
  });

  const amtInfoSchema = Yup.object({
    withdrawalAmount: Yup.string().required("Please enter an amount to withdraw"),
  });

  const otpInfoSchema = Yup.object({
    withdrawalAmount: Yup.string().required("Please enter an amount to withdraw"),
  });

  // cons;

  const accountInfoForm = useForm({ resolver: useYupValidationResolver(acctInfoSchema) });
  const amountInfoForm = useForm({ resolver: useYupValidationResolver(amtInfoSchema) });
  const otpInfoForm = useForm({ resolver: useYupValidationResolver(otpInfoSchema) });

  const onSubmit = data => {
    if (currentStep === 3) {
      setShowOtp(true);
      return;
    }
    submitForm && submitForm(data);
    getValidForm(currentStep);
  };

  const handleNext = clickType => {
    let newStep = currentStep;
    // clickType === "next" ? newStep++ : newStep--;
    newStep++;

    if (newStep > 0 && newStep <= stepsArray.length + 1) {
      setCurrentStep(newStep);
    }
  };

  const getValidForm = stepIndex => {
    switch (stepIndex) {
      case 1:
        !Object.keys(accountInfoForm.formState.errors).length && handleNext();
        break;
      case 2:
        !Object.keys(amountInfoForm.formState.errors).length && handleNext();
        break;
      case 3:
        !Object.keys(otpInfoForm.formState.errors).length && handleNext();
        break;
      default:
        return false;
    }
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 1:
        return <AccountInfo bankOptions={bankOptions} register={accountInfoForm.register} control={accountInfoForm.control} errors={accountInfoForm.formState.errors} />;
      case 2:
        return <AmountInfo register={amountInfoForm.register} errors={amountInfoForm.formState.errors} setValue={amountInfoForm.setValue} accountBalance="$283,000" />;
      case 3:
        return <OtpInfo register={otpInfoForm.register} errors={otpInfoForm.formState.errors} />;
      default:
        return "Steps Complete!";
    }
  };

  const submitFormContext = stepIndex => {
    switch (stepIndex) {
      case 1:
        return accountInfoForm.handleSubmit(onSubmit);
      case 2:
        return amountInfoForm.handleSubmit(onSubmit);
      case 3:
        return otpInfoForm.handleSubmit(onSubmit);
      default:
        return;
    }
  };

  const submitOtp = data => {
    console.table(data);
    setShowOtp(false);
    setProcessing(true);
    setTimeout(() => {
      setOpen(false);
      setProcessing(false);
    }, 1000);
  };

  return (
    <Modal isOpen={open} toggle={() => setOpen(!open)} className="withdrawal-form-container">
      <ModalHeader toggle={() => setOpen(!open)}>{title}</ModalHeader>
      <form onSubmit={submitFormContext(currentStep)}>
        <ModalBody>
          <div className="mb-25">
            <Stepper direction="horizontal" currentStepNumber={currentStep - 1} data={stepsArray} lastItemClass={currentStep === stepsArray.length && !otpVerified ? "awaiting-verify" : ""} />
          </div>

          {getStepContent(currentStep)}
        </ModalBody>
        <ModalFooter className="flex-column">
          <div className="w-100">
            <Button type="submit" fullWidth label={currentStep === 3 ? "Verify" : "Continue"} background="blue" icon={processing ? `ri-loader-5-line spin` : ""} />
          </div>

          {currentStep === 3 && (
            <div>
              <Button label="Resend OTP" background="neutral" className="otp-link" />
            </div>
          )}
        </ModalFooter>
      </form>
      <OtpForm open={showOtp} setOpen={setShowOtp} title="OTP Verification" submitForm={submitOtp} />
    </Modal>
  );
};

export default WithdrawalForm;
