import React, { useEffect } from "react";

const AmountInfo = ({ errors, register, setValue, accountBalance }) => {
  useEffect(() => {
    if (accountBalance) {
      setValue("balance", accountBalance);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountBalance]);
  return (
    <>
      <div className="mb-3">
        <label htmlFor="balance" className="form-label">
          Wallet Balance
        </label>
        <input type="text" placeholder="" className={`form-control ${errors?.balance ? "is-invalid" : ""}`} id="balance" name="balance" disabled {...register("balance")} />
        <div className="invalid-feedback">{errors?.balance?.message}</div>
      </div>

      <div className="mb-3">
        <label htmlFor="withdrawalAmount" className="form-label">
          Amount to Withdraw
        </label>
        <input
          type="text"
          placeholder=""
          className={`form-control ${errors?.withdrawalAmount ? "is-invalid" : ""}`}
          id="withdrawalAmount"
          name="withdrawalAmount"
          {...register("withdrawalAmount", { required: true })}
        />
        <div className="invalid-feedback">{errors?.withdrawalAmount?.message}</div>
      </div>
      <div className="note">
        <i className="ri-error-warning-line"></i>
        <p className="note-content">P.S : N26.88 will be deducted as bank transfer fee</p>
      </div>
    </>
  );
};

export default AmountInfo;
