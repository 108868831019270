import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "vgg-ui-react/dist/components/Core";
import { Button as RButton } from "reactstrap";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "./convertConfirm.scss";
import { useSelector } from "react-redux";
import { fetchWalletByAccount } from "../../../redux/actions/wallet.actions";


const ConvertConfirm = ({ open, setOpen, title, isConfirmed, activeAccount }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const dispatch = useDispatch();
  const { confirmingConvert, convertedWallet, confirmConvertError } =
    useSelector(({ wallet }) => {
      return {
        confirmingConvert: wallet?.confirmingConvert,
        convertedWallet: wallet?.convertedWallet,
        confirmConvertError: wallet?.confirmConvertError,
      };
    });

  useEffect(() => {
    if (
      !confirmConvertError?.data?.data &&
      !confirmConvertError?.data?.status &&
      !confirmingConvert
    ) {
      setErrorMessage(
        confirmConvertError?.data?.message ||
          "An error Occurred while processing this request"
      );
    }else{
      dispatch(fetchWalletByAccount(activeAccount));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmingConvert]);

  return (
    <div>
      <Modal isOpen={open} className="convert-form-container">
        <ModalHeader toggle={() => setOpen(!open)}>{title}</ModalHeader>
        <ModalBody>
          {confirmConvertError && !confirmingConvert && errorMessage ? (
            <p className="text-danger d-flex align-items-center">
              <i class="ri-error-warning-fill"></i> {errorMessage}
            </p>
          ) : (
            ""
          )}
          <p>{convertedWallet?.data?.data?.description}</p>
        </ModalBody>
        <ModalFooter>
          <div className="display-flex">
            <Button
              label="Cancel"
              onClick={() => setOpen(!open)}
              background="neutral"
              className="text-secondary"
            />
            <RButton color="primary" onClick={isConfirmed}>
              Confirm{" "}
              {confirmingConvert && (
                <Spinner className="me-1" size="sm" color="light"></Spinner>
              )}
            </RButton>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConvertConfirm;
