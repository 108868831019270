export const getCurrencySymbol = (currencyCode) => {
  switch (currencyCode) {
    case "NGN":
      return "₦";
    case "$":
      return "$";
    default:
      return "";
  }
};
