import React from "react";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import SVGS from "../../assets/svg";
import "./empty.scss";

const Empty = ({ title, subtitle }) => {
  return (
    <div className="empty-container h-100">
      <div>
        {SVGS.empty()}
        <div className="sp-mt--24">
          <Typography
            typographyVariant="subtitle1"
            text={title || "Nothing here"}
            className="font-weight-bold"
          />
        </div>
        <div className="sp-mt--8">
          <Typography
            typographyVariant="chart-text"
            text={
              subtitle || "When you make a transaction it would appear here"
            }
            color="rgba(0, 0, 0, 0.4)"
          />
        </div>
      </div>
    </div>
  );
};

export default Empty;
