import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { store } from "redux/store";
import App from "./App";
// import "remixicon/fonts/remixicon.css";
import "./index.scss";

// render micro frontend function
window.renderWalletapp = ({ containerId, history, userDetail }) => {
  console.log({containerId})
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App history={history} userDetail={userDetail} />
      </Router>
    </Provider>,
    document.getElementById(containerId)
  );
};

// unmount micro frontend function
window.unmountWalletapp = ({ containerId }) => {
  console.log({containerId})

  if (document.getElementById(containerId)) {
    ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
  }
};

// Mount to root if it is not a micro frontend
if (!document.getElementById("Walletapp-container")) {
  if(document.getElementById("root")) {
    ReactDOM.render(
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>,
      document.getElementById("root")
    );
  }
}
