import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { Spinner } from "reactstrap";

import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Button } from "vgg-ui-react/dist/components/Core";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "helpers/inputFormatter";
import { getCardType } from "helpers/switchCardType";
import OtpForm from "components/otpForm/OtpForm";
import classnames from "classnames";
import { useDispatch } from "react-redux";

import "./topupForm.scss";
import { generateBankAccount } from "redux/actions/wallet.actions";

const TopupForm = ({
  open,
  setOpen,
  title,
  submitForm,
  generatingAccount,
  bankAccount,
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("1");
  const [issuer, setIssuer] = useState({});
  const [showOtp, setShowOtp] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!open) {
      reset();
    }

    if (open) {
      dispatch(generateBankAccount({}));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const validationSchema = Yup.object({
    amount: Yup.string().required("Please enter an amount"),
    cardNumber:
      activeTab === "1" &&
      Yup.string().required("Please enter a valid card number"),
    cvv: activeTab === "1" && Yup.string().required("Please enter card cvv"),
    expiryDate:
      activeTab === "1" &&
      Yup.string().required("Please enter card expiry date"),
  });
  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ resolver });
  const onSubmit = (data) => {
    submitForm && submitForm(data);
    setShowOtp(true);
  };

  const handleInputChange = (evt) => {
    if (evt.target.name === "cardNumber") {
      const { value, issuer } = formatCreditCardNumber(evt.target.value);
      setValue(evt.target.name, value);
      setIssuer(issuer);
    } else if (evt.target.name === "expiryDate") {
      setValue(evt.target.name, formatExpirationDate(evt.target.value));
    } else if (evt.target.name === "cvv") {
      setValue(evt.target.name, formatCVC(evt.target.value));
    }
  };

  const submitOtp = (data) => {
    console.table(data);
    setShowOtp(false);
    setProcessing(true);
    setTimeout(() => {
      setOpen(false);
      setProcessing(false);
    }, 1000);
  };

  return (
    <Modal
      isOpen={open}
      toggle={() => setOpen(!open)}
      className="topup-form-container"
    >
      <ModalHeader toggle={() => setOpen(!open)}>{title}</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className="mb-3">
            <label htmlFor="amount" className="form-label">
              Enter Amount
            </label>
            <input
              type="text"
              // className={`form-control ${errors?.amount ? "is-invalid" : ""}`} // works fine when validating
              className="form-control"
              id="amount"
              name="amount"
              {...register("amount", { required: false })} // change required to true if you want to validate
            />
            <div className="invalid-feedback">{errors?.amount?.message}</div>
          </div>
          <div className="">
            <h6 className="form-subtitle">Account Info</h6>
          </div>

          <div className="mb-3">
            <Nav tabs className="mb-3">
              {/* <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Pay by Card
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Pay by Bank Transfer
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="0">
                <Row>
                  <Col sm="12">
                    <div className="mb-3">
                      <div className="form-group has-icon">
                        <label htmlFor="cardNumber" className="form-label">
                          Card Number
                        </label>
                        <input
                          type="tel"
                          placeholder="xxxx - xxxx - xxxx - xxxx - xx"
                          className={`form-control ${
                            errors?.cardNumber ? "is-invalid" : ""
                          }`}
                          id="cardNumber"
                          name="cardNumber"
                          // pattern="[\d| ]{16,22}"
                          {...register("cardNumber", {
                            required: activeTab === "1",
                          })}
                          onChange={handleInputChange}
                        />
                        <span className="form-control-feedback">
                          {getCardType(issuer)}
                        </span>
                        <div className="invalid-feedback">
                          {errors?.cardNumber?.message}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    <div className="mb-3">
                      <label htmlFor="expiryDate" className="form-label">
                        Expiry Date
                      </label>
                      <input
                        type="tel"
                        placeholder="MM/YY"
                        className={`form-control ${
                          errors?.expiryDate ? "is-invalid" : ""
                        }`}
                        id="expiryDate"
                        name="expiryDate"
                        // pattern="\d\d/\d\d"
                        {...register("expiryDate", {
                          required: activeTab === "1",
                        })}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        {errors?.expiryDate?.message}
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="mb-3">
                      <label htmlFor="cvv" className="form-label">
                        CVV
                      </label>

                      <input
                        type="tel"
                        placeholder="1234"
                        // pattern="\d{3,4}"
                        className={`form-control ${
                          errors?.cvv ? "is-invalid" : ""
                        }`}
                        id="cvv"
                        name="cvv"
                        {...register("cvv", {
                          required: activeTab === "1",
                          maxLength: issuer === "amex" ? 4 : 3,
                        })}
                        onChange={handleInputChange}
                      />
                      <div className="invalid-feedback">
                        {errors?.cvv?.message}
                      </div>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <div className="bank-detail d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="icon-container me-3">
                          <i className="ri-bank-line"></i>
                        </div>
                        <p className="detail-label">Bank Name</p>
                      </div>
                      <p className="detail-value">
                        {generatingAccount?<Spinner color="primary" size="sm"/>: bankAccount?.data?.responseData?.bankName || "--"}
                      </p>
                    </div>
                    <div className="bank-detail d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="icon-container me-3">
                          <i className="ri-contacts-book-2-line"></i>
                        </div>
                        <p className="detail-label">Acct Name</p>
                      </div>
                      <p className="detail-value">
                        {generatingAccount?<Spinner color="primary" size="sm"/>: bankAccount?.data?.responseData?.accountName || "--"}
                      </p>
                    </div>
                    <div className="bank-detail d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="icon-container me-3 number-icon">
                          123
                        </div>
                        <p className="detail-label">Acct Number</p>
                      </div>
                      <p className="detail-value">
                        {generatingAccount?<Spinner color="primary" size="sm"/>: bankAccount?.data?.responseData?.accountNumber || "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>

          <div className="note">
            <i className="ri-error-warning-line"></i>
            <p className="note-content">
              P.S : Charges will be deducted as bank transfer fee
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            fullWidth
            label="Confirm Payment"
            background="blue"
            icon={processing ? `ri-loader-5-line spin` : ""}
            onClick={() => setOpen(false)}
          />
        </ModalFooter>
      </form>

      <OtpForm
        open={showOtp}
        setOpen={setShowOtp}
        title="OTP Verification"
        submitForm={submitOtp}
      />
    </Modal>
  );
};

export default TopupForm;
