export const getUserDetails = () => {
  const jsondetails = localStorage.getItem("user_details");
  const userDetails = jsondetails ? JSON.parse(jsondetails) : null;
  return userDetails;
};
export const userDetails = () => (getUserDetails() ? getUserDetails() : null);
export const userDetail = getUserDetails() ? getUserDetails().user : null;
export const userToken = getUserDetails() ? getUserDetails().token : null;

export const setUserDetails = async (payload) => {
  await localStorage.setItem(
    "user_details",
    payload ? JSON.stringify(payload) : ""
  );
};

export const isLoggedIn = () => {
  const details = localStorage.getItem("user_details");
  const userExist = details ? JSON.parse(details) : null;
  return userExist ? true : false;
};

export const signout = async () => {
  await localStorage.setItem("user_details", null);
  await localStorage.removeItem("user_details");
};
