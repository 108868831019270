import { setUserDetails } from "apiServices/user.service";
import Home from "pages/home/Home";
import React from "react";
import Notification from "./shared/notifications/notifications";

function App({userDetail}) {
  setUserDetails(userDetail)
  return (
    <div className="wallet__wrapper">
      <Notification/>
      <Home/>
    </div>
  );
}

export default App;
