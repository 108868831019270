import { userDetails } from "./user.service";

export const servicemanagerBaseUrl =
  process.env.NODE_ENV === "development"
    ? "https://afservicemanagerclienttest.azurewebsites.net/api"
    : `${window.env?.servicemanagerBaseUrl}api`;

export const baseUrl =
  process.env.NODE_ENV === "development"
    ? "https://accountserviceaggregator-apitest.azurewebsites.net/api/v1"
    : `${window.env?.serviceaggregatorBaseUrl}/api/v1`;

export const config = () => {
  if (userDetails()) {
    const bearerToken = `Bearer ${userDetails().accessToken}`;
    return { headers: { Authorization: bearerToken } };
  }
  return null;
};
