import React from "react";
import { Controller } from "react-hook-form";
import SelectControl from "components/selectControl/SelectControl";
import Select from "react-select";

const AccountInfo = ({ register, errors, bankOptions, control }) => {
  return (
    <>
      <div className="mb-3">
        <label htmlFor="accountNumber" className="form-label">
          Acct Number
        </label>
        <input
          type="text"
          placeholder=""
          className={`form-control ${errors?.accountNumber ? "is-invalid" : ""}`}
          id="accountNumber"
          name="accountNumber"
          {...register("accountNumber", { required: true })}
        />
        <div className="invalid-feedback">{errors?.accountNumber?.message}</div>
      </div>

      <div className="mb-88">
        <label htmlFor="bank" className="form-label">
          Enter Bank
        </label>
        <Controller
          control={control}
          name="bank"
          id="bank"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Select
              {...register("bank", { required: true })}
              onChange={e => onChange({ target: e })}
              onBlur={onBlur}
              inputRef={ref}
              controlClassName={`form-control ${errors?.bank ? "is-invalid" : ""} ps-0 pt-0 pb-0`}
              controlErrorMsg={errors?.bank?.message}
              components={{
                IndicatorSeparator: () => null,
                Control: SelectControl,
              }}
              defaultValue={value}
              options={bankOptions}
            />
          )}
        />
      </div>
    </>
  );
};

export default AccountInfo;
