import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Button,
  Spinner,
} from "reactstrap";
import "./convertForm.scss";
import * as Yup from "yup";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import { Controller, useForm } from "react-hook-form";
import SelectControl from "components/selectControl/SelectControl";
import Select from "react-select";
import { useSelector } from "react-redux";

const ConvertForm = ({ open, setOpen, title, submitForm }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const validationSchema = Yup.object({
    amount: Yup.string().required("please enter an amount"),
    convertTo: Yup.string().required("Please choose a destination currency"),
    convertFrom: Yup.string().required("Please choose a currency"),
  });

  const { convertingWallet, getWallet, convertWalletFailure } = useSelector(
    ({ wallet }) => {
      return {
        convertingWallet: wallet?.convertingWallet,
        getWallet: wallet?.getWallet?.data?.data,
        convertWalletFailure: wallet?.convertWalletFailure,
      };
    }
  );

  useEffect(() => {
    if (
      !convertWalletFailure?.data?.data &&
      !convertWalletFailure?.data?.status &&
      !convertingWallet
    ) {
      setErrorMessage(
        convertWalletFailure?.data?.message + ". Please try again" ||
          "An error Occurred while processing this request"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertWalletFailure]);

  const onCloseModal = () => {
    setOpen(!open);
    reset();
    setErrorMessage(null);
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ resolver: useYupValidationResolver(validationSchema) });

  const userWallets =
    getWallet?.map((wallet) => {
      return {
        value: wallet.accountId,
        label: wallet.walletName?.toUpperCase() || wallet.currency + " WALLET",
      };
    }) || [];

  return (
    <Modal isOpen={open} className="convert-form-container">
      <ModalHeader toggle={onCloseModal}>{title}</ModalHeader>
      <ModalBody>
        {convertWalletFailure && !convertingWallet && errorMessage ? (
          <p className="text-danger d-flex align-items-center">
            <i class="ri-error-warning-fill"></i> {errorMessage}
          </p>
        ) : (
          ""
        )}
        <Form onSubmit={handleSubmit(submitForm)}>
          <div className="mb-3">
            <label htmlFor="convertFrom" className="form-label">
              From
            </label>
            <Controller
              control={control}
              name="convertFrom"
              id="convertFrom"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  {...register("convertFrom", { required: true })}
                  onBlur={onBlur}
                  onChange={(e) => onChange({ target: e })}
                  inputRef={ref}
                  controlClassName={`form-control ${
                    errors?.convertFrom ? "is-invalid" : ""
                  } ps-0 pt-0 pb-0`}
                  controlErrorMsg={errors?.convertFrom?.message}
                  components={{
                    IndicatorSeparator: () => null,
                    Control: SelectControl,
                  }}
                  defaultValue={value}
                  options={userWallets}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="convertTo" className="form-label">
              To
            </label>
            <Controller
              control={control}
              name="convertTo"
              id="convertTo"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Select
                  {...register("convertTo", { required: true })}
                  onBlur={onBlur}
                  onChange={(e) => onChange({ target: e })}
                  inputRef={ref}
                  controlClassName={`form-control ${
                    errors?.convertTo ? "is-invalid" : ""
                  } ps-0 pt-0 pb-0`}
                  controlErrorMsg={errors?.convertTo?.message}
                  components={{
                    IndicatorSeparator: () => null,
                    Control: SelectControl,
                  }}
                  defaultValue={value}
                  options={userWallets}
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="amount" className="form-label">
              Amount
            </label>
            <input
              type="number"
              placeholder="Amount"
              pattern="\d{0,9}"
              className={`form-control ${errors?.amount ? "is-invalid" : ""}`}
              id="amount"
              name="amount"
              {...register("amount", { required: true })}
            />
            <div className="invalid-feedback">{errors?.amount?.message}</div>
          </div>
          <Button type="submit" color="primary" className="w-100">
            Convert{" "}
            {convertingWallet && (
              <Spinner className="me-1" size="sm" color="light"></Spinner>
            )}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ConvertForm;
