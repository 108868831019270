import { walletActionTypes } from "../actions/wallet.actions";
import { REQUEST, FAILURE, SUCCESS } from "../action-type.util";

const INITIAL_STATE = {
  fetchingWallet: false,
  wallet: null, // This is the real initial wallet value
  // wallet: {
  //   currency: "NGN",
  //   availableBalance: "238056",
  //   ledgerBalance: "238056",
  // },                // used for testing purpose

  fetchingTransactions: false,
  transactions: [],

  creatingWallet: false,
  creatWalletSuccess: null,
  creatWalletFailure: null,

  generatingAccount: false,
  bankAccount: null,
  generateAccountFailure: null,
};

export const walletReducer = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case REQUEST(walletActionTypes.GET_WALLET):
      return {
        ...state,
        getWalletLoading: true,
      };

    case SUCCESS(walletActionTypes.GET_WALLET):
      return {
        ...state,
        getWalletLoading: false,
        getWallet: actions.payload,
      };

    case FAILURE(walletActionTypes.GET_WALLET):
      return {
        ...state,
        getWalletLoading: false,
      };
    case REQUEST(walletActionTypes.FETCH_WALLET):
      return {
        ...state,
        fetchingWallet: true,
      };

    case SUCCESS(walletActionTypes.FETCH_WALLET):
      return {
        ...state,
        fetchingWallet: false,
        walletData: actions.payload,
      };

    case FAILURE(walletActionTypes.FETCH_WALLET):
      return {
        ...state,
        fetchingWallet: false,
      };
    case REQUEST(walletActionTypes.FETCH_TRANSACTIONS_BY_ACCOUNT):
      return {
        ...state,
        fetchingTransactions: true,
        transactions: null
      };

    case SUCCESS(walletActionTypes.FETCH_TRANSACTIONS_BY_ACCOUNT):
      return {
        ...state,
        fetchingTransactions: false,
        transactions: actions.payload,
      };

    case FAILURE(walletActionTypes.FETCH_TRANSACTIONS_BY_ACCOUNT):
      return {
        ...state,
        fetchingTransactions: false,
      };
    case REQUEST(walletActionTypes.CREATE_WALLET):
      return {
        ...state,
        creatingWallet: true,
        createWalletSuccess: null,
        creatWalletFailure: null
      };

    case SUCCESS(walletActionTypes.CREATE_WALLET):
      return {
        ...state,
        creatingWallet: false,
        createWalletSuccess: actions.payload,
        createWalletFailure: null,
      };

    case FAILURE(walletActionTypes.CREATE_WALLET):
      return {
        ...state,
        creatingWallet: false,
        createWalletSuccess: null,
        createWalletFailure: actions.payload,
      };
    case REQUEST(walletActionTypes.GENERATE_BANK_ACCOUNT):
      return {
        ...state,
        generatingAccount: true,
      };

    case SUCCESS(walletActionTypes.GENERATE_BANK_ACCOUNT):
      return {
        ...state,
        generatingAccount: false,
        bankAccount: actions.payload,
        generateAccountFailure: null,
      };

    case FAILURE(walletActionTypes.GENERATE_BANK_ACCOUNT):
      return {
        ...state,
        generatingAccount: false,
        bankAccount: null,
        generateAccountFailure: actions.payload,
      };

    case REQUEST(walletActionTypes.CONVERTWALLETVALUE):
      return {
        ...state,
        convertingWallet: true,
        convertedWallet: null,
        convertWalletFailure: null,
      };
    case SUCCESS(walletActionTypes.CONVERTWALLETVALUE):
      return {
        ...state,
        convertingWallet: false,
        convertedWallet: actions.payload,
        convertWalletFailure: null,
      };
    case FAILURE(walletActionTypes.CONVERTWALLETVALUE):
      return {
        ...state,
        convertingWallet: false,
        convertedWallet: null,
        convertWalletFailure: actions.payload,
      };
    case REQUEST(walletActionTypes.CONFIRMCONVERSION):
      return {
        ...state,
        confirmingConvert: true,
        confirmedConvert: null,
        confirmConvertError: null,
      };
    case SUCCESS(walletActionTypes.CONFIRMCONVERSION):
      return {
        ...state,
        confirmingConvert: false,
        confirmConvert: actions.payload,
        confirmConvertError: null,
      };
    case FAILURE(walletActionTypes.CONFIRMCONVERSION):
      return {
        ...state,
        confirmingConvert: false,
        confirmConvert: null,
        confirmConvertError: actions.payload,
      };
    case REQUEST(walletActionTypes.GET_CURRENCY):
      return {
        ...state,
        getCurrencySuccess: null,
        getCurrencyError: null,
        getCurrencyloading: true,
      };
    case SUCCESS(walletActionTypes.GET_CURRENCY):
      return {
        ...state,
        getCurrencyloading: false,
        getCurrencySuccess: actions.payload?.data?.responseData?.items,
        getCurrencyError: null,
      };
    case FAILURE(walletActionTypes.GET_CURRENCY):
      return {
        ...state,
        getCurrencyloading: false,
        getCurrencySuccess: null,
        getCurrencyError: actions.payload,
      };

    default:
      return { ...state };
  }
};
