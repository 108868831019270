import React from "react";
import "./statusModal.scss";
import { Modal, ModalBody } from "reactstrap";
import { Button } from "vgg-ui-react/dist/components/Core";
import doneIcon from "assets/images/done.svg";

const StatusModal = ({ open, setOpen }) => {
  return (
    <Modal isOpen={open} className="convert-status-container">
      <ModalBody>
        <div className="container">
          <div>
            <div className="w-100 d-flex">
              <img src={doneIcon} alt="success icon" className="mx-auto" />
            </div>
            <p className="status-header text-center">Conversion Successful</p>
            <p className="status-body text-center">
              Check your USD wallet to see your new balance
            </p>
          </div>
          <Button
            fullWidth
            label="Done"
            background="blue"
            onClick={() => setOpen(!open)}></Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default StatusModal;
