import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "vgg-ui-react/dist/components/Core";
import CurrencySelector from "../CurrencySelector";

const CurrencySelectorModal = ({ open, setOpen}) => {
  
  return (
    <Modal isOpen={open} className="otp-form-container" size="sm" centered>
      <ModalHeader toggle={() => setOpen(!open)}>Add new wallet</ModalHeader>
        <ModalBody>
          <CurrencySelector/>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button type="submit" label="Close" background="red" onClick={() => setOpen(false)}/>
        </ModalFooter>
     </Modal>
  );
};

export default CurrencySelectorModal;
