import React, { useState } from "react";
import NumberFormat from "react-number-format";
import Select from "react-select";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getCurrencySymbol } from "helpers/currencySymbol";
import Typography from "vgg-ui-react/dist/components/Core/Typography";

import "./currencySelected.scss";
import { fetchWalletByAccount } from "redux/actions/wallet.actions";
import CurrencySelectorModal from "../currencySelector/currencySelectorModal/CurrencySelectorModal";

const CurrencySelected = ({
  isMobile,
  onTopUp,
  onConvert,
  wallet,
  setActiveAccount,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const { getWallet, walletData } = useSelector(({ wallet }) => wallet);

  const options = getWallet?.data?.data?.map((val) => {
    return { value: val?.accountId, label: val?.currency };
  });

  const onCurrencyChange = (e) => {
    setSelectedCurrency(e);
    setActiveAccount(e.value);
    dispatch(fetchWalletByAccount(e.value));
  };
  return (
    <>
      <CurrencySelectorModal setOpen={setOpen} open={open} />
      <div className="top flex-column flex-md-row">
        <div className="currency">
          <div style={{ width: 100 }} className="selectedCurrencySelect">
            <Select
              options={options}
              onChange={onCurrencyChange}
              value={selectedCurrency || options?.[0]}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div className="buttons-div mt-3 mt-md-0">
          <div className="w-130">
            <Button
              className={`topup-btn ${isMobile ? "btn-small" : ""}`}
              size="sm"
              onClick={(e) => onTopUp && onTopUp(e)}
            >
              <span>
                <i className="ri-arrow-left-up-line topup-icon"></i>
              </span>
              Top Up
            </Button>
          </div>
          <div className="w-130">
            <Button
              className={`withdraw-btn ${isMobile ? "btn-small" : ""}`}
              size="sm"
              onClick={() => setOpen(true)}
            >
              New&nbsp;Wallet
            </Button>
          </div>
          <div className="w-130">
            <Button
              className={`convert-btn ${isMobile ? "btn-small" : ""}`}
              size="sm"
              color="primary"
              outline
              onClick={(e) => onConvert && onConvert(e)}
            >
              <span>
                <i className="ri-restart-line convert-icon"></i>
              </span>
              Convert
            </Button>
          </div>
        </div>
      </div>
      <div className="bottom sp-pt--8 sp-pb--8 sp-pl--24 sp-pr--24">
        <div className="left w-50">
          <Typography
            typographyVariant="caption"
            text="Available Balance"
            className="sp-text-color--neutral-500 sp-mt--8 sp-mb--16"
          />

          <Typography
            typographyVariant={isMobile ? "section-title" : "disp-heading"}
            text={
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={walletData?.data?.data?.availableBalance || "0"}
                prefix={getCurrencySymbol(wallet?.currency)}
                decimalSeparator="."
                displayType="text"
                type="text"
                thousandSeparator={true}
                allowNegative={true}
                fixedDecimalScale={true}
              />
            }
            className="sp-text-color--neutral-800 font-weight-bold sp-mb--16"
          />

          <Typography
            typographyVariant="caption"
            text="This is money available for your transactions"
            className="sp-text-color--neutral-500 sp-mt--8 sp-mb--16"
          />
        </div>
        <div className="right w-50 sp-pl--24">
          <Typography
            typographyVariant="caption"
            text="Ledger Balance"
            className="sp-text-color--neutral-500 sp-mt--8 sp-mb--16"
          />

          <Typography
            typographyVariant={isMobile ? "section-title" : "disp-heading"}
            text={
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={walletData?.data?.data?.ledgerBalance || "0"}
                prefix={getCurrencySymbol(wallet?.currency)}
                decimalSeparator="."
                displayType="text"
                type="text"
                thousandSeparator={true}
                allowNegative={true}
                fixedDecimalScale={true}
              />
            }
            className="sp-text-color--neutral-800 font-weight-bold sp-mb--16"
          />

          <Typography
            typographyVariant="caption"
            text="This is your holding balance"
            className="sp-text-color--neutral-500 sp-mt--8 sp-mb--16"
          />
        </div>
      </div>
    </>
  );
};

export default CurrencySelected;
