import { getUserDetails } from "apiServices/user.service";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Spinner } from "reactstrap";
import { errorMessage } from "redux/actions/notification/notification";
import {
  createWalletByCurrency,
  fetchWalletByAccount,
  getCurrency
} from "redux/actions/wallet.actions";
import Typography from "vgg-ui-react/dist/components/Core/Typography";
import "./currencySelector.scss";

const CurrencySelector = ({ onChoose }) => {
  const [createStatus, setCreateStatus] = useState(false)
  const dispatch = useDispatch();
  const { fetchingWallet, wallet,createWalletSuccess, creatingWallet, createWalletFailure, getCurrencySuccess } = useSelector(
    ({ wallet }) => wallet
  );

  useEffect(()=> {
     if(createWalletFailure && !creatingWallet && createStatus) {
      setCreateStatus(false)
      dispatch(errorMessage(createWalletFailure?.data?.message || "Error occured while processing this request"));
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingWallet]);

  useEffect(()=> {
     if(!createWalletSuccess?.data?.status && !creatingWallet && createStatus) {
      setCreateStatus(false)
      dispatch(errorMessage(createWalletSuccess?.data?.message));
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingWallet])

 
  useEffect(()=>{
     dispatch(getCurrency());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  
  const onCurrencyChange = (e) => {
    if (e.value) {
      dispatch(fetchWalletByAccount(e.value));
      setSelectedCurrency(e);
    }
  };
  const handleBtnClick = (e) => {
    
    if (wallet) {
      onChoose && onChoose({ selectedCurrency: selectedCurrency?.value });
    } else {
      setCreateStatus(true)
      dispatch(
        createWalletByCurrency({
          currency: selectedCurrency.value,
          firstName: getUserDetails()?.user?.firstName,
          lastName: getUserDetails()?.user?.lastName
        })
      );
    }
  };
  return (
    <div className="d-flex align-items-center justify-content-center flex-column w-100 h-100 currencySelector">
      <Typography
        typographyVariant="caption"
        text="Select your default currency"
        className="sp-text-color--neutral-500 sp-mt--8 sp-mb--16"
      />
      <div style={{ width: 110 }} className="sp-mb--16">
        <Select
          options={getCurrencySuccess?.map(val =>{
            return {value: val?.currencyCode, label: val?.currencyCode}
           })}
          onChange={onCurrencyChange}
          value={selectedCurrency}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      </div>

      <Button
        className={`topup-btn sp-mb--32 d-flex align-items-center`}
        size="sm"
        onClick={handleBtnClick}
        disabled={!selectedCurrency || fetchingWallet || creatingWallet}
      >
        {fetchingWallet ? (
          <Spinner size="sm" as="span" tag="span" color="default" type="grow">
            ""
          </Spinner>
        ) : (
          <>
            {creatingWallet && (
              <Spinner
                size="sm"
                as="span"
                tag="span"
                color="default"
                type="grow"
              >
                ""
              </Spinner>
            )}

            {!selectedCurrency ? (
              <span className="ms-3">Select Currency</span>
            ) : wallet ? (
              <span className="ms-3">View Details</span>
            ) : (
              <span className="ms-3">
                {creatingWallet ? "Creating..." : "Create Wallet"}
              </span>
            )}
          </>
        )}
      </Button>
    </div>
  );
};

export default CurrencySelector;
