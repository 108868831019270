import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button } from "vgg-ui-react/dist/components/Core";
import { useYupValidationResolver } from "helpers/yupValidationResolver";
import "./otpForm.scss";

const OtpForm = ({ open, setOpen, title, submitForm }) => {
  useEffect(() => {
    if (!open) {
      reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const validationSchema = Yup.object({
    otp: Yup.string().required("Please enter the OTP sent to your phone"),
  });
  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver });
  const onSubmit = data => {
    submitForm && submitForm(data);
  };

  return (
    <Modal isOpen={open} toggle={() => setOpen(!open)} className="otp-form-container" size="sm" centered>
      <ModalHeader toggle={() => setOpen(!open)}>{title}</ModalHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody>
          <div className="mb-3">
            <label htmlFor="otp" className="form-label">
              Enter OTP
            </label>
            <input type="text" className={`form-control ${errors?.otp ? "is-invalid" : ""}`} id="otp" name="otp" {...register("otp", { required: true })} />
            <div className="invalid-feedback">{errors?.otp?.message}</div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" fullWidth label="Verify OTP" background="blue" />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default OtpForm;
