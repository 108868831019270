import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TopupForm from "./topupForm/TopupForm";
import WithdrawalForm from "./withdrawalForm/WithdrawalForm";

import "./home.scss";
import Wallet from "pages/wallet/Wallet";
import ConvertForm from "./convertForm/ConvertForm";
import StatusModal from "pages/home/statusModal/StatusModal";
import {
  convertWalletvalue,
  confirmConversion,
} from "redux/actions/wallet.actions";
import ConvertConfirm from "./convertConfirm/ConvertConfirm";
import {
  fetchTransactionsByAccount,
  fetchWallet,
  fetchWalletByAccount,
} from "redux/actions/wallet.actions";

const Home = () => {
  const dispatch = useDispatch();
  const [showTopup, setShowTopup] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showConvert, setShowConvert] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [activeAccount, setActiveAccount] = useState(null);

  const {
    fetchingTransactions,
    transactions,
    fetchingWallet,
    wallet,
    generatingAccount,
    bankAccount,
    walletData,
    getWallet,
    getWalletLoading,
    generateAccountFailure,
    convertedWallet,
    confirmConvert,
  } = useSelector(({ wallet }) => wallet);

  useEffect(() => {
    if (convertedWallet) {
      setShowConfirm(true);
    }
  }, [convertedWallet]);

  useEffect(() => {
    if (confirmConvert) {
      setShowConfirm(false);
      setShowConvert(false);
      setShowStatus(true);
    }
  }, [confirmConvert]);

  const handleWalletTopup = (data) => {
    console.table({ ...data });
  };

  const handleWalletWithdraw = (data) => {
    console.table({ ...data });
  };

  const handleConvertSubmit = (data) => {
    console.log({data})
    const payload = {
      fromWalletID: data.convertFrom,
      toWalletID: data.convertTo,
      sourceAmount: Number(data.amount),
    };
    dispatch(convertWalletvalue(payload));
  };

  const handleConfirmConverstion = () => {
    const payload = {
      confirmationID: convertedWallet?.data?.data?.confirmationID,
    };
    dispatch(confirmConversion(payload));
  };

  useEffect(() => {
    if (walletData) {
      dispatch(fetchTransactionsByAccount(walletData?.data?.data?.accountNo));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingWallet]);

  useEffect(() => {
    if (getWallet) {
      setActiveAccount(getWallet?.data?.data?.[0]?.accountId)
      dispatch(fetchWalletByAccount(getWallet?.data?.data?.[0]?.accountId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWalletLoading]);

  useEffect(() => {
    dispatch(fetchWallet());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wallet__container">
      <TopupForm
        open={showTopup}
        setOpen={setShowTopup}
        title="Top-Up Wallet"
        generatingAccount={generatingAccount}
        bankAccount={bankAccount}
        generateAccountFailure={generateAccountFailure}
        submitForm={handleWalletTopup}
      />
      <WithdrawalForm
        open={showWithdraw}
        setOpen={setShowWithdraw}
        title="Withdraw from Wallet"
        submitForm={handleWalletWithdraw}
      />
      <ConvertForm
        open={showConvert}
        setOpen={setShowConvert}
        title="Convert"
        submitForm={handleConvertSubmit}
      />

      <ConvertConfirm
        open={showConfirm}
        setOpen={setShowConfirm}
        title="Confirm"
        isConfirmed={handleConfirmConverstion}
        activeAccount={activeAccount}
      />

      <StatusModal open={showStatus} setOpen={setShowStatus} />

      <Wallet
        transactions={transactions}
        wallet={wallet}
        fetchingTransactions={fetchingTransactions}
        onTopUp={() => setShowTopup(!showTopup)}
        onWithdrawal={() => setShowWithdraw(!showWithdraw)}
        onConvert={() => setShowConvert(!showConvert)}
        setActiveAccount={setActiveAccount}
      />
    </div>
  );
};

export default Home;
