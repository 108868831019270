import React, { useState, useEffect } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Table } from "vgg-ui-react/dist/components/Core";
import "./wallet.scss";
import Empty from "components/empty/Empty";
import { useMediaQuery } from "react-responsive";
import TableActionDropdown from "components/tableActionDropdown/tableActionDropdown";
import CurrencySelected from "pages/home/currencySelected/CurrencySelected";
import CurrencySelector from "pages/home/currencySelector/CurrencySelector";
import { Spinner } from "reactstrap";
import {
  fetchWallet,
} from "../../redux/actions/wallet.actions";

const Wallet = ({
  onTopUp,
  onWithdrawal,
  onConvert,
  transactions,
  fetchingTransactions,
  wallet,
  setActiveAccount
}) => {
  const dispatch = useDispatch();
  
  const {
    getWallet,
    getWalletLoading,creatingWallet, createWalletSuccess
   } = useSelector(({ wallet }) => wallet);
 
   const currencyOptions = [
    { value: "NGN", label: "NGN" },
    { value: "USD", label: "USD" },
  ];

  const [selectedCurrency, setSelectedCurrency] = useState(
    currencyOptions.find(
      (v) => v.value.toLowerCase() === wallet?.currency.toLowerCase()
    )
  );
  const isMobile = useMediaQuery({ maxWidth: "457px" });
  
  
  const onSelectCurrency = (evt) => {
    setSelectedCurrency(evt);
  };

  
  useEffect(()=> {
    if(createWalletSuccess?.data?.status && !creatingWallet) {
     dispatch(fetchWallet())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [creatingWallet])

  return (
    <div className="container wallet-container">
      <h1 className="page-title">Wallet</h1>
      {!getWalletLoading && <div className={`header-section ${isMobile ? "header-section-sm" : ""}`}>
        {getWallet?.data?.data?.length > 0 ? (
          <CurrencySelected
            selectedCurrency={selectedCurrency}
            isMobile={isMobile}
            onTopUp={onTopUp}
            onConvert={onConvert}
            onWithdrawal={onWithdrawal}
            currencies={currencyOptions}
            wallet={wallet}
            setActiveAccount={setActiveAccount}
          />
        ) : (
          <CurrencySelector
            onCurrencyChange={onSelectCurrency}
            selectedCurrency={selectedCurrency}
            isMobile={isMobile}
            onConvert={onConvert}
            onWithdrawal={onWithdrawal}
            currencies={currencyOptions}
          />
        )}
      </div>}
      <div className="main">
        <h4 className="transact">Transactions</h4>
        {(getWalletLoading || fetchingTransactions) ? (
          <div
            className="d-flex align-items-center justify-content-center bg-light"
            style={{ height: "50vh" }}
          >
            <Spinner color="primary" size="lg">
              {null}
            </Spinner>
          </div>
        ) : (
          <>
            {transactions?.data?.data && (
              <div className="table-container">
                <Table>
                  <tbody>
                    {transactions?.data?.data?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <span
                            className={`icon-container ${
                              item.transactionType==='Deposit' ? "credited" : "debited"
                            }`}
                          >
                            {item.transactionType==='Deposit' ? (
                              <i className="ri-arrow-right-down-line"></i>
                            ) : (
                              <i className="ri-arrow-left-up-line"></i>
                            )}
                          </span>
                        </td>
                        <td>{item.transactionType}</td>
                        {/* <td>
                          <span className="me-2">
                            <img src={item.card} alt="card" />
                          </span>
                          {item.cardNumber}
                        </td> */}
                        <td>{item.amount}</td>
                        <td>{item.transactionDate}</td>
                        {/* <td>{item.transactionDate}</td> */}
                        <td>
                          <TableActionDropdown
                            data={item}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
            {(transactions?.data?.data?.length === 0 || !transactions?.data?.data) && <Empty />}
          </>
        )}
      </div>
    </div>
  );
};

export default Wallet;
