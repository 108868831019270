export const cards = [
  {
    type: "mastercard",
    pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    format: /(\d{1,4})/g,
    length: [16],
    cvcLength: [3],
    luhn: true,
  },
  {
    type: "visa",
    pattern: /^4/,
    format: /(\d{1,4})/g,
    length: [13, 16],
    cvcLength: [3],
    luhn: true,
  },
];
