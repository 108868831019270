import { cards } from "../constants/cards";

export function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function cardFromNumber(num) {
  var card, foundCard, j, len, match;
  num = (num + "").replace(/\D/g, "");
  foundCard = void 0;
  for (j = 0, len = cards.length; j < len; j++) {
    card = cards[j];
    if ((match = num.match(card.pattern))) {
      if (!foundCard || match[0].length > foundCard[1][0].length) {
        foundCard = [card, match];
      }
    }
  }
  return foundCard && foundCard[0];
}

export function cardType(num) {
  var ref;
  if (!num) {
    return null;
  }
  return ((ref = cardFromNumber(num)) != null ? ref.type : void 0) || null;
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = cardType(value);

  const clearValue = clearNumber(value);
  let nextValue;

  nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;

  return { value: nextValue.trim(), issuer };
}

export function formatCVC(value) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2) > 12 ? 12 : clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}
